import { useSelector } from 'react-redux';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './link.module.scss';

const Link = (props) => {
  const {
    children,
    allWidth,
    target,
    primary,
    className,
    linkStyle,
    altText,
    center,
    absolutePath,
    href,
    handleClick,
    shouldRedirect,
    noWidth,
    ...rest
  } = props;

  const linkClass = cn({
    [styles.link]: true,
    [styles.primary]: primary,
    [styles.allWidth]: allWidth,
    [styles.center]: center,
    [className]: !!className
  });

  let store = useSelector((state) => state.store);
  if (store === process.env.NEXT_PUBLIC_STORE_ID) store = undefined;

  const handleLinkClick = (e) => {
    if (
      shouldRedirect &&
      typeof shouldRedirect === 'function' &&
      shouldRedirect(e)
    ) {
      e.preventDefault();
    } else if (handleClick) {
      handleClick(e);
    }
  };

  return (
    <NextLink
      href={!absolutePath && store ? `/${store}${href}` : href}
      passHref
      title={altText}
      target={target}
      className={allWidth ? styles.allWidth : undefined}
      onClick={handleLinkClick}
      nowidth={noWidth ? 'true' : 'false'}
      {...rest}
    >
      <div
        className={linkClass}
        style={{ ...linkStyle }}
      >
        {children}
      </div>
    </NextLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  altText: PropTypes.string,
  children: PropTypes.node.isRequired,
  allWidth: PropTypes.bool,
  target: PropTypes.string,
  primary: PropTypes.bool,
  className: PropTypes.string,
  linkStyle: PropTypes.object,
  center: PropTypes.bool,
  handleClick: PropTypes.func,
  absolutePath: PropTypes.bool,
  shouldRedirect: PropTypes.func,
  noWidth: PropTypes.bool
};

Link.defaultProps = {
  altText: '',
  allWidth: false,
  target: '_self',
  primary: false,
  className: '',
  linkStyle: {},
  center: false,
  handleClick: () => {},
  absolutePath: false,
  shouldRedirect: () => {},
  noWidth: false
};

export default Link;
