const variables = {
  /* ---Colors--- */
  /* Brand Colors */
  'color-biciva': '#aadc01',
  'color-futbolva': '#60c140',
  'color-kidsva': '#f28a2f',
  'color-outdoorva': '#c06b45',
  'color-runningva': '#3782e8',
  'color-tenisva': '#e96a26',
  'color-trainingva': '#9765f4',
  'color-urbanva': '#60d3af',
  'color-vaypolva': '#ffbe1e',
  'color-vaypol': '#ffbe1e',
  'color-+sports': '#ffbe1e',
  'color-black': '#121212',
  'color-black-70': '#121212b3',
  'color-white': '#b8b8b8',
  'color-white-light': '#e5e5e5',
  'color-white-absolute': '#fff',
  'color-white-dark': '#a0a0a0',
  'color-backdrop': '#000000c4',
  'color-grey': '#1a1a1a',
  'color-grey-lighter': '#818181',
  'color-grey-light': '#313131',
  'color-grey-dark': '#282828',

  /* Another Colors */
  'color-red-offer': '#E20A16FA',
  'color-favorite-fully': '#E20A16FA',
  /* Status Colors */
  'color-info': '#0076d1',
  'color-success': '#357e38',
  'color-error': '#b42818',
  'color-warning': '#e8cd30',

  /* ---Border Radius--- */
  'br-soft': '8px',
  'br-medium': '12px',
  'br-hard': '15px',

  /* ---Zindex--- */
  'z-spinner': 240,
  'z-modal': 200,
  'z-modal-close-icon': 210,
  'z-backdrop-filters': 90,
  'z-nav-fixed': 10,
  'z-content-top': 80,
  'z-content': 8,
  'z-chevron': 3,
  'z-content-bottom': 7,
  'z-background-top': 2,
  'z-background': 0,

  /* ---Breakpoints--- */
  desktop: 1000
};

export default variables;
