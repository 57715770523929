/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';

import cn from 'classnames';

import styles from './image.module.scss';
import Skeleton from '../Skeleton';

const Image = (props) => {
  const {
    src,
    width,
    height,
    br,
    alt,
    imageStyles,
    squared,
    cover,
    contain,
    fill,
    reverse,
    widthSkeleton,
    heightSkeleton,
    skeletonDark,
    absoluteSkeleton,
    className,
    onClick,
    priority
  } = props;

  const imageClass = cn({
    [styles.brHard]: br === 'hard',
    [styles.brMedium]: br === 'medium',
    [styles.brSoft]: br === 'soft',
    [styles.reverse]: reverse,
    [styles.cover]: cover,
    [styles.contain]: contain,
    [className]: Object.keys(className).length > 0 || className
  });

  const renderImage = () => {
    if (src) {
      return (
        <img
          alt={alt}
          src={src}
          className={imageClass}
          height={height}
          width={width}
          loading={priority ? 'eager' : 'lazy'}
          style={{
            ...(fill
              ? {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }
              : {}),
            ...imageStyles
          }}
          onClick={onClick && onClick}
        />
      );
    }
    return (
      <Skeleton
        absoluteSkeleton={absoluteSkeleton}
        dark={skeletonDark}
        width={widthSkeleton || `${width}px`}
        height={(!squared ? heightSkeleton : widthSkeleton) || `${height}px`}
      />
    );
  };

  return <>{renderImage()}</>;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  br: PropTypes.oneOf(['hard', 'medium', 'soft', 'none']),
  squared: PropTypes.bool,
  fill: PropTypes.bool,
  cover: PropTypes.bool,
  contain: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alt: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  imageStyles: PropTypes.object,
  className: PropTypes.string,
  widthSkeleton: PropTypes.string,
  heightSkeleton: PropTypes.string,
  skeletonDark: PropTypes.bool,
  absoluteSkeleton: PropTypes.bool,
  priority: PropTypes.bool,
  onClick: PropTypes.func
};

Image.defaultProps = {
  squared: false,
  br: 'none',
  width: 100,
  height: 100,
  imageStyles: {},
  fill: false,
  reverse: false,
  cover: false,
  contain: false,
  className: '',
  widthSkeleton: '',
  heightSkeleton: '',
  skeletonDark: false,
  absoluteSkeleton: false,
  onClick: null,
  priority: false
};

export default Image;
