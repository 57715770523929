import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import cn from 'classnames';
import styles from './text.module.scss';
import Skeleton from '@/components/atoms/Skeleton';

const sizes = {
  xxs: '0.625rem',
  xs: '0.75rem',
  s: '0.875rem',
  m: '1rem',
  l: '1.125rem',
  xl: '1.25rem',
  xxl: '1.375rem'
};

const Text = (props) => {
  const {
    center,
    children,
    className,
    colored,
    forInput,
    showSkeleton,
    strike,
    textCase,
    textColor,
    textSize,
    textStyle,
    variant,
    weight,
    maxLineLength
  } = props;
  const [showMore, setShowMore] = useState(true);
  const ref = useRef(null);
  const [hasBeenOpen, setHasBeenOpen] = useState(false);
  const textClass = cn({
    [styles.text]: true,
    [styles.light]: weight === 'light',
    [styles.normal]: weight === 'normal',
    [styles.semibold]: weight === 'semibold',
    [styles.bold]: weight === 'bold',
    [styles.bolder]: weight === 'bolder',
    [className]: !!className,
    [styles.strike]: strike,
    [styles.center]: center,
    [styles.colored]: colored
  });

  let render = children;
  if (textCase === 'uppercase' && !showSkeleton)
    render = `${
      typeof children === 'string' ? children : children.join(' ')
    }`.toUpperCase() || (
      <Skeleton
        dark
        height={sizes[textSize]}
      />
    );
  if (textCase === 'capitalize' && !showSkeleton) {
    const capital = children?.[0].toUpperCase();
    const text = children?.substring(1);
    render = capital + text || (
      <Skeleton
        dark
        height={sizes[textSize]}
      />
    );
  }
  if (showSkeleton)
    render = (
      <Skeleton
        dark
        height={sizes[textSize]}
      />
    );
  const maxLengthStyles = {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: maxLineLength
  };
  const renderText = () => {
    switch (variant) {
      case 'h1':
        return (
          <h1
            ref={ref}
            className={textClass}
            style={{
              color: colored ? undefined : textColor,
              fontSize: sizes[textSize],
              ...(maxLineLength && showMore ? maxLengthStyles : {}),
              ...textStyle
            }}
            htmlFor={forInput}
          >
            {render}
          </h1>
        );
      case 'h2':
        return (
          <h2
            ref={ref}
            className={textClass}
            style={{
              color: colored ? undefined : textColor,
              fontSize: sizes[textSize],
              ...(maxLineLength && showMore ? maxLengthStyles : {}),
              ...textStyle
            }}
            htmlFor={forInput}
          >
            {render}
          </h2>
        );
      case 'h3':
        return (
          <h3
            ref={ref}
            className={textClass}
            style={{
              color: colored ? undefined : textColor,
              fontSize: sizes[textSize],
              ...(maxLineLength && showMore ? maxLengthStyles : {}),
              ...textStyle
            }}
            htmlFor={forInput}
          >
            {render}
          </h3>
        );
      case 'h4':
        return (
          <h4
            ref={ref}
            className={textClass}
            style={{
              color: colored ? undefined : textColor,
              fontSize: sizes[textSize],
              ...(maxLineLength && showMore ? maxLengthStyles : {}),
              ...textStyle
            }}
            htmlFor={forInput}
          >
            {render}
          </h4>
        );
      case 'span':
        return (
          <span
            ref={ref}
            className={textClass}
            style={{
              color: colored ? undefined : textColor,
              fontSize: sizes[textSize],
              ...(maxLineLength && showMore ? maxLengthStyles : {}),
              ...textStyle
            }}
            htmlFor={forInput}
          >
            {render}
          </span>
        );

      default:
        return (
          <p
            ref={ref}
            className={textClass}
            style={{
              color: colored ? undefined : textColor,
              fontSize: sizes[textSize],
              ...(maxLineLength && showMore ? maxLengthStyles : {}),
              ...textStyle
            }}
            htmlFor={forInput}
          >
            {render}
          </p>
        );
    }
  };
  return (
    <>
      {renderText()}
      {maxLineLength &&
        (ref?.current?.scrollHeight > ref?.current?.clientHeight ||
          hasBeenOpen) && (
          <button
            onClick={() => {
              setShowMore(!showMore);
              if (!hasBeenOpen) {
                setHasBeenOpen(true);
              }
            }}
            style={{
              color: 'var(--color-primary)',
              textDecoration: 'underline',
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              marginTop: '10px'
            }}
          >
            Ver {showMore ? 'más' : 'menos'}
          </button>
        )}
    </>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'span', 'p']),
  weight: PropTypes.oneOf(['light', 'normal', 'semibold', 'bold', 'bolder']),
  textColor: PropTypes.string,
  textCase: PropTypes.oneOf(['uppercase', 'capitalize', 'none']),
  textStyle: PropTypes.object,
  strike: PropTypes.bool,
  center: PropTypes.bool,
  colored: PropTypes.bool,
  className: PropTypes.string,
  forInput: PropTypes.string,
  textSize: PropTypes.oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl']),
  showSkeleton: PropTypes.bool,
  maxLineLength: PropTypes.number
};

Text.defaultProps = {
  variant: 'p',
  weight: 'normal',
  textColor: 'var(--color-white-absolute)',
  textCase: 'none',
  textStyle: {},
  strike: false,
  center: false,
  colored: false,
  className: '',
  forInput: null,
  textSize: 'm',
  showSkeleton: false,
  maxLineLength: null
};

export default Text;
