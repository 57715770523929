import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Image from '../Image';
import styles from './index.module.scss';

const BrandImage = ({
  primary,
  brand,
  isDark,
  height,
  width,
  event,
  maxWidth,
  imageWidth,
  imageHeight
}) => {
  const storeId = useSelector((state) => state.store);

  return (
    <div
      key={brand || storeId}
      className={styles.img}
      style={{ height: `${height}px`, width: width || 'auto' }}
    >
      <Image
        alt={`${primary ? storeId : brand} logo`}
        src={`/brandlogos/${primary ? storeId : brand}${
          isDark ? '-black' : ''
        }${!isDark && event ? '-event' : ''}.webp`}
        imageStyles={{ maxWidth }}
        width={imageWidth || 'auto'}
        height={imageHeight}
      />
    </div>
  );
};

BrandImage.propTypes = {
  brand: PropTypes.string,
  isDark: PropTypes.bool,
  primary: PropTypes.bool,
  height: PropTypes.number,
  maxWidth: PropTypes.string,
  event: PropTypes.bool,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  width: PropTypes.string
};
BrandImage.defaultProps = {
  isDark: false,
  primary: false,
  height: 25,
  brand: 'vaypol',
  maxWidth: 'none',
  event: false,
  imageWidth: 'auto',
  imageHeight: '100',
  width: 'auto'
};

export default BrandImage;
